import React, { useState, useEffect } from "react";
import Logo from "../../Images/Logo.png";
import menuSanduiche from "../../Images/menuSanduiche.svg";
import X from "../../Images/X.png";
import "./Header.scss";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const [page, setPage] = useState("");

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    console.log(isOpen);
  };

  const toggleMenuFechar = () => {
    setIsOpen(false);
    console.log(isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      let page = localStorage.getItem("PaginaAtual");
      setPage(page);
    }, 1000);
  }, []);

  return (
    <div className={`Container-Header ${isScrolled ? "scrolled" : ""}`}>
      <div className="Content-Header">
        <div className="Logo">
          <a href="/inicio">
            <img src={Logo} alt="Logo" />
          </a>
        </div>
        <div className={`Menu-Links ${isOpen ? "openMenu-Links open" : ""}`}>
          <a
            href="/inicio"
            className={`nochecked ${page === "inicio" ? "checked" : null}`}
          >
            INÍCIO
          </a>
          <a
            href="/sobre"
            className={`nochecked ${page === "sobre" ? "checked" : null}`}
          >
            SOBRE
          </a>
          <a
            href="/servicos"
            className={`nochecked ${page === "servico" ? "checked" : null}`}
          >
            SERVIÇOS
          </a>
          <a
            href="/contato"
            className={`nochecked ${page === "contato" ? "checked" : null}`}
          >
            CONTATO
          </a>
        </div>
        <div
          className={`Menu-Sanduiche ${isOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>
    </div>
  );
}

export default Header;
