import { React, useEffect, useState } from "react";
import Missao from "../../Images/Missao.png";
import Eye from "../../Images/Eye.png";
import Diamont from "../../Images/Diamont.png";

import "./Sobre.scss";

function Sobre() {
  useEffect(() => {
    localStorage.setItem("PaginaAtual", "sobre");
  }, []);
  return (
    <>
      <div className="container-sobre">
        <div className="container-sobre-banner">
          <div className="container-sobre-inf">
            <h1>SOBRE NÓS </h1>

            <p>
              A VR STEEL é uma empresa líder no mercado de soluções industriais,
              destacando-se na montagem de equipamentos e na fabricação e
              instalação de peças nos segmentos de moinhos, indústrias
              alimentícias, gás e petróleo. Nosso compromisso é oferecer um
              trabalho profissional e diferenciado, focado na qualidade dos
              serviços prestados e na satisfação do cliente.
              <br />
              <br /> Com um quadro profissional altamente qualificado, contamos
              com montadores, encanadores, soldadores e caldeireiros que possuem
              uma vasta experiência comprovada nas áreas em que atuamos. Além
              disso, nossa oficina está equipada com máquinas, equipamentos e
              ferramentas de última geração, permitindo-nos oferecer serviços de
              fabricação de dutos, bifurcais, setores, anéis, pulmão, silos,
              escadas, plataformas, mezaninos e muito mais.
            </p>
          </div>
        </div>

        <div className="container-sobre-fundamentos">
          <div className="container-sobre-fundamentos-isbox">
            <img src={Missao} alt="Logo Missao" />

            <h2>MISSÃO</h2>
            <p>
              Nosso compromisso é criar soluções industriais inovadoras, visando
              qualidade, agilidade, eficiência e durabilidade para atender às
              necessidades de nossos clientes
            </p>
          </div>
          <div className="container-sobre-fundamentos-isbox">
            <img src={Eye} alt="Logo Eye" />

            <h2>VISÃO</h2>
            <p>
              Ser reconhecidos como referência no atendimento industrial em
              diversos segmentos, fornecendo soluções tecnológicas e inovadoras
              de alta complexidade
            </p>
          </div>
          <div className="container-sobre-fundamentos-isbox">
            <img src={Diamont} alt="Logo Diamont" />

            <h2>VALORES</h2>
            <p>
              Comprometimento com o cliente, compromisso com a alta qualidade,
              inovação constante com foco em oportunidades, valorização e
              desenvolvimento dos colaboradores
            </p>
          </div>
        </div>

        <div className="container-sobre-parallax">
          <div className="container-sobre-parallax-inf">
            <h2>
              Quer conhecer melhor nossas soluções ou discutir um projeto?
              <br />
              Entre em contato conosco para descobrir como podemos ajudar a
              levar seu negócio para o próximo nível.
            </h2>

            <button>ENTRAR EM CONTATO</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sobre;
