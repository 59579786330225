import { Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Header from "../Components/Headers/Header";
import Inicio from "../Pages/Inicio/Inicio";
import Sobre from "../Pages/Sobre/Sobre";
import Servico from "../Pages/Servico/Servico";
import Contato from "../Pages/Contato/Contato";
import Footer from "../Components/Footer/Footer";
import Preload from "../Components/Preload/Preload";
import Zapzap from "../Components/Whats/Zapzap";

function Router() {
  const [load, setLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, [1000]);
  }, []);
  return (
    <div className="Container-Route">
      <>
        <Header />
        {load ? <Preload /> : null}

        <Zapzap />
        <Routes>
          <Route path="/*" element={<Inicio />} />
          <Route path="/incio" element={<Inicio />} />
          <Route path="/sobre" element={<Sobre />} />

          <Route path="/servicos" element={<Servico />} />

          <Route path="/contato" element={<Contato />} />
        </Routes>
        <Footer />
      </>
    </div>
  );
}

export default Router;
