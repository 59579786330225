import React from "react";

import "./Carrosel.scss";

import FabricaFortaleza from "../../Images/Fabrica logo.png";
import foto2 from "../../Images/GME logo.png";
import foto3 from "../../Images/GMA logo.png";
import foto4 from "../../Images/Macedo Logo.png";
import foto5 from "../../Images/M dias Logo.png";
import foto6 from "../../Images/Macedo Logo.png";
import foto7 from "../../Images/GMA logo.png";
import foto8 from "../../Images/M dias Logo.png";
function Carrosel() {
  return (
    <div class="slider">
      <div class="slide-track">
        <div class="slide">
          <img src={FabricaFortaleza} />
        </div>
        <div class="slide">
          <img src={foto2} />
        </div>
        <div class="slide">
          <img src={foto3} />
        </div>
        <div class="slide">
          <img src={foto4} />
        </div>
        <div class="slide">
          <img src={foto5} />
        </div>
        <div class="slide">
          <img src={foto6} />
        </div>
        <div class="slide">
          <img src={foto7} />
        </div>
        <div class="slide">
          <img src={foto8} />
        </div>
        <div class="slide">
          <img src={FabricaFortaleza} />
        </div>
        <div class="slide">
          <img src={foto2} />
        </div>
        <div class="slide">
          <img src={foto3} />
        </div>
        <div class="slide">
          <img src={foto4} />
        </div>
        <div class="slide">
          <img src={foto5} />
        </div>
        <div class="slide">
          <img src={foto6} />
        </div>
        <div class="slide">
          <img src={FabricaFortaleza} />
        </div>
        <div class="slide">
          <img src={foto2} />
        </div>
        <div class="slide">
          <img src={foto3} />
        </div>
        <div class="slide">
          <img src={foto4} />
        </div>
        <div class="slide">
          <img src={foto5} />
        </div>
        <div class="slide">
          <img src={foto6} />
        </div>
        <div class="slide">
          <img src={foto7} />
        </div>
        <div class="slide">
          <img src={foto8} />
        </div>
        <div class="slide">
          <img src={FabricaFortaleza} />
        </div>
        <div class="slide">
          <img src={foto2} />
        </div>
        <div class="slide">
          <img src={foto3} />
        </div>
        <div class="slide">
          <img src={foto4} />
        </div>
        <div class="slide">
          <img src={foto5} />
        </div>
        <div class="slide">
          <img src={foto6} />
        </div>
      </div>
      ''
    </div>
  );
}

export default Carrosel;
