import { React, useState, useEffect } from "react";
import "./Servico.scss";

import Moinho from "../../Images/Moinho.jpg";
import Industrias from "../../Images/Industrias.jpg";
import Alimenticia from "../../Images/Alimenticia.jpg";
import Bebidas from "../../Images/Bebidas.jpg";
import Gas from "../../Images/Gas.jpeg";
import Petroleo from "../../Images/Petroloe.jpg";

function Servico() {
  useEffect(() => {
    localStorage.setItem("PaginaAtual", "servico");
  }, []);

  const serv = [
    "Fabricação de tubulações em aço inox e carbono",
    "Instalações e reforma de equipamentos diversos",
    "Adequação de NR12",
    "Fabricação de tanques",
    "Fabricação de galpão e estruturas metálicas",
    "Manutenção preventiva e corretiva",
    "Fabricação de plataformas mezaninos",
    "Fabricação de escada de marinheiro",
    "Adequação a NR12",
    "Desenho em 3D",
    "Projetos e atualização de planta baixa em 2D",
    "Caldeiraria leve e pesada",
    "Fabricação de suporte para qualquer equipamento",
    "Isolamento térmicos",
    "Fabricação de dutos de aspiração ou insuflamentos",
    "Conexões",
    "Fabricação de dutos",
    "Fabricação de Bifurcais",
    "Fabricação de Setores",
    "Fabricação de Anéis",
    "Fabricação de Pulmão",
    "Fabricação de Silos",
    "Fabricação de Escadas",
    "Fabricação de Plataformas",
    "Fabricação de Mezaninos",
    "Fabricação de Curvas",
    "Fabricação de Registros de válvulas",
    "Fabricação de Calças",
    "Fabricação de Cone",
    "Fabricação de Moegas",
    "Fabricação de Conexões",
    "Fabricação de Projetos de tubulações",
    "Projetos de tubulações",
  ];

  return (
    <>
      <div className="container-servico">
        <div className="container-servico-segmentos">
          <h1>SEGMENTOS </h1>

          <div className="container-servico-segmentos-content">
            <div className="container-servico-segmentos-isbox">
              <img src={Moinho} alt="Logo Moinho" />
              <h2>Moinhos</h2>
            </div>
            <div className="container-servico-segmentos-isbox">
              <img src={Industrias} alt="Logo Industrias" />
              <h2>Indústrias </h2>
            </div>
            <div className="container-servico-segmentos-isbox">
              <img src={Alimenticia} alt="Logo Alimenticia" />
              <h2>Alimentício</h2>
            </div>
            <div className="container-servico-segmentos-isbox">
              <img src={Bebidas} alt="Logo Bebidas" />
              <h2>Bebidas</h2>
            </div>
            <div className="container-servico-segmentos-isbox">
              <img src={Gas} alt="Logo Gas" />
              <h2>Gás </h2>
            </div>
            <div className="container-servico-segmentos-isbox">
              <img src={Petroleo} alt="Logo Petroleo" />
              <h2>Petróleo</h2>
            </div>
          </div>
        </div>
        <div className="container-servico-servicos">
          <h1>SERVIÇOS</h1>

          <div className="container-servico-servicos-content">
            {serv.map((ser, index) => {
              return (
                <div className="container-servico-servicos-isbox" key={index}>
                  <h2>{ser}</h2>
                </div>
              );
            })}{" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default Servico;
