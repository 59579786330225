import React from "react";
import "./Footer.scss";
import Logo from "../../Images/Logo.png";
import Instagram from "../../Images/Instagram.png";
import Zapzap from "../../Images/Zapzap.png";
function Footer() {
  return (
    <>
      <div className="container-footer">
        <div className="container-footer-section">
          <div className="container-footer-section-localizacao">
            <img src={Logo} alt="" />
            <h3>Av. Frei Cirilo – Messejana</h3>
            <h3>Fortaleza – CE</h3>
            <h3>CEP 60840-285</h3>
          </div>
          <div className="container-footer-section-institucional">
            <h2>INSTITUCIONAL</h2>
            <a href="/incio">Início</a>
            <a href="/sobre">Sobre</a>
            <a href="/servico">Serviços</a>
            <a href="/contato">Contato</a>
          </div>
          <div className="container-footer-section-contato">
            <h2>CONTATO</h2>

            <p>vrmontagemind@gmail.com</p>
            <p>vrsteelmontagem@gmail.com</p>
          </div>
          <div className="container-footer-section-redes">
            <h2>REDES SOCIAIS</h2>
            <div className="container-footer-section-redes-links">
              <a
                href="https://www.instagram.com/vrsteelmontagens/"
                target="_blank"
              >
                <img src={Instagram} alt="Logo Instagram " />
              </a>
              <a href="https://wa.me/+5585999005796?text=Quero%20solicitar%20um%20or%C3%A7amento!%F0%9F%91%8D">
                <img src={Zapzap} alt="Logo Zapzap " />
              </a>
            </div>
          </div>
        </div>
        <p>
          &copy; 2024 VR STEEL <br />
          Todos os direitos reservados
        </p>
      </div>
    </>
  );
}

export default Footer;
