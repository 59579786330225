import { React, useEffect, useState } from "react";
import "./Contato.scss";

import Instagram from "../../Images/Instagram.png";
import Zapzap from "../../Images/Zapzap.png";
import ImgContato from "../../Images/Logo.png";

function Contato() {
  useEffect(() => {
    localStorage.setItem("PaginaAtual", "contato");
  }, []);
  return (
    <>
      <div className="container-contato">
        <h1>FALE CONOSCO</h1>
        <div className="container-contato-content">
          <div className="container-contato-inf">
            <div className="container-contato-contato">
              <h2>CONTATO</h2>

              <p>vrmontagemind@gmail.com</p>
              <p>vrsteelmontagem@gmail.com</p>
            </div>
            <div className="container-contato-redes">
              <h2>REDES SOCIAIS</h2>

              <a
                href="https://www.instagram.com/vrsteelmontagens/"
                target="_blank"
              >
                <img src={Instagram} alt="Logo Instagram " />
              </a>
            </div>
            <div className="container-contato-zapzap">
              <h2>SOLICITE UM ORÇAMENTO</h2>
              <a href="https://wa.me/+5585999005796?text=Quero%20solicitar%20um%20or%C3%A7amento!%F0%9F%91%8D">
                WhatsApp <img src={Zapzap} alt="Logo Zapzap " />
              </a>
            </div>
          </div>
        </div>
        <div className="container-contato-content">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3980.9591288867628!2d-38.499346688618466!3d-3.8189019436249105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7b8a9ee9ef6b9f7%3A0xa6924ea8273ed544!2s(METALURGICA)%20V%20R%20STEEL%20MONTAGENS!5e0!3m2!1spt-BR!2sbr!4v1708993952844!5m2!1spt-BR!2sbr"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Contato;
