import { React, useEffect, useState } from "react";
import "./Inicio.scss";
import Logo from "../../Images/Logo.png";
import Preload from "../../Components/Preload/Preload";
import Consultoria from "../../Images/icons8-strategy-100.png";
import Equipamentos from "../../Images/icons8-butt-welding-80.png";
import Instalacao from "../../Images//icons8-tools-100.png";
import Suporte from "../../Images/icons8-support-100.png";
import Arrow from "../../Images/icons8-arrow-64.png";
import Carrosel from "../../Components/Carrosel/Carrosel";
import Projeto01 from "../../Images/Projetos VR01.jpg";
import Projeto02 from "../../Images/Projetos VR02.jpg";
import Projeto03 from "../../Images/Projetos VR03.jpg";
import Projeto04 from "../../Images/Projetos VR04.jpg";
import Projeto05 from "../../Images/Projetos VR05.jpg";
import Projeto06 from "../../Images/Projetos VR06.jpg";
import Projeto07 from "../../Images/Projetos VR07.jpg";
import Projeto08 from "../../Images/Projetos VR08.jpg";
import Projeto09 from "../../Images/Projetos VR09.jpg";
import Projeto10 from "../../Images/Projetos VR10.jpg";

function Home() {
  const imageList = [
    Projeto01,
    Projeto02,
    Projeto03,
    Projeto04,
    Projeto05,
    Projeto06,
    Projeto07,
    Projeto08,
    Projeto09,
    Projeto10,
  ];

  useEffect(() => {
    localStorage.setItem("PaginaAtual", "inicio");
  }, []);

  const homeFluxo = [
    {
      imgIcone: Consultoria,
      title: "Planejamento",
      iconeArrow: Arrow,
      subtitle01: "Análise precisa das necessidades do cliente",
      subtitle02: " Desenvolvimento de plantas e projetos sob medida",
      subtitle03:
        " Avaliações para garantir a conformidade com normas do setor",
      subtitle04: " Suporte contínuo durante todo o ciclo de projeto",
    },
    {
      imgIcone: Equipamentos,
      title: "Fabricação",
      iconeArrow: Arrow,
      subtitle01: "Produção personalizada de equipamentos",
      subtitle02: "Integração de tecnologias de ponta e automação",
      subtitle03: "Testes de qualidade para eficácia dos equipamentos",
      subtitle04: "Flexibilidade na adaptação a mudanças de projeto",
    },
    {
      imgIcone: Instalacao,
      title: "Instalação",
      iconeArrow: Arrow,
      subtitle01: " Assistência completa na instalação",
      subtitle02: "Suporte para atualizações tecnológicas",
      subtitle03: "Treinamento para operadores e técnicos",
      subtitle04: "Avaliação pós-instalação para garantir alto desempenho",
    },
    ,
    {
      imgIcone: Suporte,
      title: "Suporte Pós-Venda e Manutenção",
      iconeArrow: Arrow,
      subtitle01: "Disponibilidade de peças de reposição",
      subtitle02: "Suporte contínuo e garantia",
      subtitle03: "Avaliações para garantir a conformidade com normas do setor",
      subtitle04: "Assistência técnica rápida e eficiente",
    },
  ];
  return (
    <>
      <div className="home-banner"></div>
      <div className="container-home">
        <div className="container-home-sobre">
          <div className="container-home-sobre-text">
            <div></div>
            <h1>VR STEEL MONTAGENS INDUSTRIAIS</h1>

            <p>
              A VR STEEL é uma empresa líder no mercado de soluções industriais,
              destacando-se na montagem de equipamentos e na fabricação e
              instalação de peças em setores variados, como moinhos, indústrias
              alimentícias, gás e petróleo. Contamos com uma equipe experiente
              de montadores, encanadores, soldadores e caldeireiros, respaldados
              por uma oficina equipada com máquinas e ferramentas de última
              geração. Isso nos permite oferecer serviços personalizados de
              fabricação de componentes diversos, incluindo dutos, bifurcais,
              silos e escadas, atendendo às necessidades específicas de cada
              cliente.
              <br />
              <br />
              Além disso, somos especializados na produção sob medida de
              componentes de aspiração, como curvas, registros de válvulas e
              moegas, além de projetos completos de tubulação. Na indústria em
              geral, destacamo-nos na fabricação de tubulações em aço inox e
              carbono, bem como na instalação e reforma de equipamentos
              diversos, garantindo a conformidade com normas de segurança, como
              a NR12. Com foco na qualidade e durabilidade, também oferecemos
              serviços de fabricação de tanques, construção de galpões e
              estruturas metálicas, proporcionando soluções completas e
              eficientes para nossos clientes.
            </p>
          </div>
          <div className="container-home-sobre-imagem">
            <img src={Logo} alt="Logo VR" />
          </div>
        </div>

        <div className="container-home-fluxo">
          <h1>FLUXO DE SERVIÇO</h1>

          <div className="section-home-fluxo">
            {homeFluxo.map((fluxo, index) => {
              return (
                <div className="section-home-fluxo-isbox" key={index}>
                  <img src={fluxo.imgIcone} alt="Img Logo" />

                  <h2>{fluxo.title} </h2>
                  <p>
                    <img src={fluxo.iconeArrow} alt="Arrow Logo" />
                    {fluxo.subtitle01}
                  </p>
                  <p>
                    <img src={fluxo.iconeArrow} alt="Arrow Logo" />
                    {fluxo.subtitle02}
                  </p>
                  <p>
                    <img src={fluxo.iconeArrow} alt="Arrow Logo" />
                    {fluxo.subtitle03}
                  </p>
                  <p>
                    <img src={fluxo.iconeArrow} alt="Arrow Logo" />
                    {fluxo.subtitle04}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="container-home-carrosel">
          <h1>NOSSOS PARCEIROS </h1>

          <Carrosel />
        </div>

        <div className="container-home-galeria">
          <h1>CONFIRA NOSSA GALERIA</h1>

          <div className="container-home-galeria">
            {imageList.map((imageUrl, index) => (
              <img
                className="galeria-img"
                key={index}
                src={imageUrl}
                alt={`Imagem ${index}`}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
