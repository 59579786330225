import React from "react";
import "./Zapzap.scss";
import ZapzapLogo from "../../Images/Zapzap.png";

function Zapzap() {
  return (
    <>
      <div className="container-zapzap-logo">
        <a href="https://wa.me/+5585999005796?text=Quero%20solicitar%20um%20or%C3%A7amento!%F0%9F%91%8D">
          <img src={ZapzapLogo} alt="Logo Zapzap" />
        </a>
      </div>
    </>
  );
}

export default Zapzap;
