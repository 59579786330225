import React from "react";
import "./Preload.scss";
import Logo from "../../Images/Logo.png";
import Gif from "../../Images/Gif.gif";

function Preload() {
  return (
    <div className="container-preload">
      <img src={Logo} alt="Logo VR" />
    </div>
  );
}

export default Preload;
